
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { HydrantData, DeviceInfo, BindDeviceInfo } from '@/types/hydrant.d'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private searchInfo = {
    fightingNum: ''
  }

  private loading = false
  private dataList: Array<HydrantData> = []
  page = 1
  size = 10
  total = 0
  private isShowDevice = false
  private isLoadingDevice = false
  private currentFightingId = ''
  private operateLine = -1
  private deviceList: Array<BindDeviceInfo> = []
  private typeBaseList: Array<DeviceInfo> = []
  private typeSelectList: Array<DeviceInfo> = []
  private unBindDeviceList: Array<DeviceInfo> = []
  private deviceForm = this.initDeviceForm

  private deviceRules = {
    deviceTypeId: [
      { required: true, message: '请选择设备类型' }
    ],
    deviceId: [
      { required: true, message: '请选择设备名称' }
    ]
  }

  get projectId () {
    return this.$route.params.projectId as string
  }

  get initDeviceForm () {
    return {
      deviceTypeId: '',
      deviceId: ''
    }
  }

  created () {
    this.getData()
    this.getTypeList()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<HydrantData> }>(this.$apis.hydrant.selectFireFightingInfoList, {
      projectId: this.projectId,
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  toHistory (row: HydrantData) {
    this.$router.push({ name: 'hydrantMonitorListHistory', params: { projectId: this.projectId, id: row.fightingId }, query: { fightingNum: row.fightingNum } })
  }

  // 设备管理
  onManage (fightingId: string) {
    this.currentFightingId = fightingId
    this.operateLine = -1
    this.deviceForm = { ...this.initDeviceForm }
    this.deviceList = []
    this.isShowDevice = true
    this.getBindDeviceList()
  }

  addBindDevice () {
    if (this.operateLine === -1) {
      this.operateLine = this.deviceList.length
      this.deviceList.push(this.initDeviceForm)
    } else {
      this.$message.warning('请先绑定正在编辑的')
    }
  }

  // 获取消防栓设备类型列表
  getTypeList () {
    this.$axios.get(this.$apis.hydrant.selectFireFightingDeviceTypeList).then(res => {
      this.typeBaseList = res || []
    })
  }

  // 获取消防栓绑定设备列表
  getBindDeviceList () {
    this.isLoadingDevice = true
    this.$axios.get(this.$apis.hydrant.selectFireFightingDeviceListBindlist, {
      fightingId: this.currentFightingId
    }).then(res => {
      this.deviceList = res || []
      if (this.deviceList.length === this.typeBaseList.length) {
        this.typeSelectList = []
      } else {
        this.typeSelectList = [...this.typeBaseList]
        this.deviceList.forEach(item => {
          const ind = this.typeSelectList.findIndex(obj => obj.deviceTypeId === item.deviceTypeId)
          if (ind >= 0) {
            this.typeSelectList.splice(ind, 1)
          }
        })
      }
    }).finally(() => {
      this.isLoadingDevice = false
    })
  }

  // 根据设备类型获取消防栓未绑定设备列表
  getUnBindDeviceList (deviceTypeId: string) {
    this.deviceForm.deviceId = ''
    this.$axios.get(this.$apis.hydrant.selectFireFightingDeviceListUnBindlist, {
      projectId: this.projectId,
      deviceTypeId: deviceTypeId
    }).then(res => {
      this.unBindDeviceList = res || []
    })
  }

  // 绑定设备
  bindDevice () {
    (this.$refs.deviceManage as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.hydrant.updateBindFireFighting, {
          fightingId: this.currentFightingId,
          deviceId: this.deviceForm.deviceId
        }).then(() => {
          this.$message.success('绑定成功')
          this.getData()
          this.getBindDeviceList()
          this.operateLine = -1
          this.deviceForm = { ...this.initDeviceForm }
        })
      }
    })
  }

  // 解绑设备
  unBindDevice (deviceId: string) {
    this.$confirm('确认解绑该设备嘛？', '提示').then(() => {
      this.$axios.post(this.$apis.hydrant.updateUnBindFireFighting, {
        deviceId
      }).then(() => {
        this.$message.success('解绑成功')
        this.getData()
        this.getBindDeviceList()
        this.deviceForm = { ...this.initDeviceForm }
      })
    })
  }
}
